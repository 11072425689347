import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch, HashRouter, Link } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <App />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
  );
};

export const ReactApp = () => (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <UnsupportedBrowserWarning>
        <HashRouter>
          <AppStateProvider>
            <Link to="/room/:URLRoomName/:URLName"></Link>
            <Link to="/room/:URLRoomName"></Link>
            <Switch>
              <Route path="/room/:URLRoomName/:URLName">
                <VideoApp />
              </Route>
              <Route path="/room/:URLRoomName">
                <VideoApp />
              </Route>
            </Switch>
          </AppStateProvider>
        </HashRouter>

      </UnsupportedBrowserWarning>
    </MuiThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
